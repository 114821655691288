<template>
  <div class='indexbox'>
    <div class="searchbox">
      <div class="left" v-hasPermi="['dig']">
        <el-input class="inp" size='small' v-model="keywords" placeholder="Type Text To Search" clearable
          style="width: 200px" @change='pagechage(1)'></el-input>
        <el-date-picker v-model="timearr" style="max-width:280px;margin-right:10px;" format='yyyy-MM-dd'
          value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="Order Date Start"
          end-placeholder="End" clearable @change='pagechage(1)' />
        <el-select size='small' style="max-width:180px;margin-right:10px;" clearable v-model="status"
          placeholder="Status" @change='pagechage(1)'>
          <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select size='small' style="max-width:180px" clearable v-model="substatus" placeholder="Substatus"
          @change='pagechage(1)'>
          <el-option v-for="item in substatusList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="right">
        <el-button type="primary" :loading='btn_loadingg' @click="exportNew">Export List</el-button>
        <el-button type="primary" :loading='btn_loadingd' @click="Updateorder">Refresh</el-button>
        <el-button type="primary" :loading='btn_loadinge' @click="exportList">Export Picking List
        </el-button>
        <el-button type="primary" @click="getdata(); getfile()">Email Picking List</el-button>
      </div>
    </div>
    <div class="tablebox">
      <div class="draggable" v-loading='loading'>
        <el-table :data="tableData" stripe @selection-change="SelectionChange" max-height='700px'>
          <el-table-column type="selection" fixed align='center' width='60' />
          <el-table-column prop="OrderNumber" label="SO#" min-width='150' align='center' fixed />
          <el-table-column prop="Reference" label="Reference" align='center' min-width='140' show-overflow-tooltip />
          <el-table-column prop="AssemblyNumber" label="Assembly Number" min-width='180' align='center'
            show-overflow-tooltip />
          <el-table-column prop="SKU" label="SKU" min-width='180' align='center' show-overflow-tooltip />
          <el-table-column prop="Name" label="Product Name" min-width='180' align='center' show-overflow-tooltip />
          <el-table-column prop="Quantity" label="Quantity" min-width='180' align='center' show-overflow-tooltip />
          <el-table-column prop="OrderDate" label="Order Date" min-width='180' align='center' show-overflow-tooltip />
          <el-table-column prop="Location" label="Location" min-width='180' align='center' show-overflow-tooltip />
          <el-table-column prop="Bin" label="Bin" min-width='180' align='center' show-overflow-tooltip />
          <el-table-column prop="StockOnHand" label="Stock On Hand" min-width='180' align='center'
            show-overflow-tooltip />
          <el-table-column prop="Status" label="Status" min-width='180' align='center' show-overflow-tooltip />
          <el-table-column prop="Substatus" label="Substatus" min-width='180' align='center' show-overflow-tooltip />
          <el-table-column prop="PickedQty" label="Picked Qty" min-width='180' align='center' show-overflow-tooltip />
          <el-table-column prop="PackedQty" label="Packed Qty" min-width='180' align='center' show-overflow-tooltip />
          <el-table-column prop="ShippedQty" label="Shipped Qty" min-width='180' align='center' show-overflow-tooltip />
          <el-table-column prop="InvoicedQty" label="Invoiced Qty" min-width='180' align='center'
            show-overflow-tooltip />
          <el-table-column prop="AvailabilityStock" label="Stock Availability" min-width='180' align='center'
            show-overflow-tooltip />
        </el-table>
      </div>
      <el-pagination @current-change='pagechage' @size-change="handleSizeChange" :page-sizes="[10, 20, 50, 100]"
        background layout="total,sizes,prev, pager, next" v-show="total > 0" :current-page.sync="page"
        :page-size="page_size" :total="total" />
    </div>
    <el-dialog title="Email Picking List" :visible.sync="show" width="60%" @close="dialogClose">
      <el-form ref="form" :model="formData" :rules="rules" label-width="120px">
        <el-form-item label="Title" prop="title">
          <el-input v-model="formData.title" clearable placeholder="Enter Title"></el-input>
        </el-form-item>
        <el-form-item label="Content" prop="content">
          <el-input v-model="formData.content" type="textarea" clearable placeholder="Enter Content"></el-input>
        </el-form-item>
        <el-form-item label="To Email" prop="to_email">
          <el-input v-model="formData.to_email" clearable placeholder="Enter To Email"></el-input>
        </el-form-item>
        <el-form-item label="Attachment" prop="attachment">
          <div style="display: flex;align-items: center;"><i class="el-icon-document-checked"></i>
            <p style="margin-left: 10px;">PickingList.xls</p>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-hasPermi="['tol']" type="primary" :loading='btn_loadingf' @click="confirmEmail">Confirm</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import { getToken } from '@/utils/auth'
  export default {
    data() {
      return {
        statusList: [
          {
            value: 'Not Yet Pickable',
            label: 'Not Yet Pickable'
          },
          {
            value: 'To be picked',
            label: 'To be picked'
          },
          {
            value: 'To be packed',
            label: 'To be packed'
          },
          {
            value: 'To be Shipped',
            label: 'To be Shipped'
          },
          {
            value: 'To be Invoiced',
            label: 'To be Invoiced'
          },
          {
            value: 'Invoiced',
            label: 'Invoiced'
          },
          {
            value: 'CLOSED',
            label: 'CLOSED'
          },
          {
            value: 'VOIDED',
            label: 'VOIDED'
          },
        ],
        substatusList: [
          {
            value: 'Not yet generated',
            label: 'Not yet generated'
          },
          {
            value: 'Authorised',
            label: 'Authorised'
          },
          {
            value: 'In progress',
            label: 'In progress'
          },
          {
            value: 'Completed - to be transferred',
            label: 'Completed - to be transferred'
          },
          {
            value: 'Pick, Pack and Ship',
            label: 'Pick, Pack and Ship'
          },
          {
            value: 'Pick, Pack and hold',
            label: 'Pick, Pack and hold'
          },
          {
            value: 'Pick, Merge and ship',
            label: 'Pick, Merge and ship'
          },
          {
            value: 'Pick, Merge and Hold',
            label: 'Pick, Merge and Hold'
          },
          {
            value: 'Pack and Ship',
            label: 'Pack and Ship'
          },
          {
            value: 'Pack and hold',
            label: 'Pack and hold'
          },
          {
            value: 'Pack, Merge and ship',
            label: 'Pack, Merge and ship'
          },
          {
            value: 'Pack, Merge and Hold',
            label: 'Pack, Merge and Hold'
          },
          {
            value: 'Hold',
            label: 'Hold'
          },
          {
            value: 'Merge and ship',
            label: 'Merge and ship'
          },
          {
            value: 'Ship',
            label: 'Ship'
          },
          {
            value: 'Invoice fully',
            label: 'Invoice fully'
          },
          {
            value: 'Can Invoice partially',
            label: 'Can Invoice partially'
          },
          {
            value: 'SO Invoiced fully',
            label: 'SO Invoiced fully'
          },
          {
            value: 'SO Invoiced partially',
            label: 'SO Invoiced partially'
          },
          {
            value: 'None',
            label: 'None'
          },
          {
            value: 'CLOSED',
            label: 'CLOSED'
          },
          {
            value: 'VOIDED',
            label: 'VOIDED'
          },
        ],
        timearr: [],
        status: '',
        substatus: '',
        keywords: '',

        total: 0,
        page: 1,
        page_size: 10,
        tableData: [],
        loading: false,
        btn_loading: false,
        btn_loadingd: false,
        btn_loadinge: false,
        btn_loadingf: false,
        btn_loadingg: false,
        idsarr: [],

        show: false,
        formData: {
          title: '',
          content: '',
          to_email: '',
          attachment: '',
        },
        rules: {
          title: [
            { required: true, message: 'Please Enter Title', trigger: 'blur' }
          ],
          content: [
            { required: true, message: 'Please Enter Content', trigger: 'blur' }
          ],
          to_email: [
            { required: true, message: 'Please Enter To Email', trigger: 'blur' }
          ],
          attachment: [
            { required: true, message: 'Please Enter Attachment', trigger: 'blur' }
          ],
        },

      };
    },
    computed: {
      ...mapGetters(['api_id']),
    },
    methods: {
      //列表
      getlist() {
        this.loading = true
        this.$post({
          url: "/app/digital_orders/pageList",
          data: {
            page: this.page,
            page_size: this.page_size,
            api_id: this.api_id,
            keywords: this.keywords,
            Status: this.status,
            Substatus: this.substatus,
            OrderDateStart: this.timearr && this.timearr.length ? this.timearr[0] : '',
            OrderDateEnd: this.timearr && this.timearr.length ? this.timearr[1] : '',
          },
          success: (res) => {
            this.page = res.data.current_page
            this.page_size = Number(res.data.per_page)
            this.tableData = res.data.data
            this.total = res.data.total
            this.Quantity = res.data.Quantity
            setTimeout(() => {
              this.loading = false
            }, 200);
          },
          tip: () => {
            this.loading = false
          },
        })
      },
      // 分页
      pagechage(page) {
        this.page = page
        this.tableData = []
        this.getlist()
      },
      // 分页
      handleSizeChange(size) {
        this.page_size = size
        this.tableData = []
        this.getlist()
      },
      // Refresh
      refresh() {
        this.btn_loading = true
        this.$post({
          url: "/app/digital_orders/refresh",
          data: {
            api_id: this.api_id,
          },
          success: (res) => {
            this.$notify({
              title: res.msg,
              type: 'success'
            });
            setTimeout(() => {
              this.btn_loading = false
              this.pagechage(1)
            }, 1300);
          },
          tip: () => {
            setTimeout(() => {
              this.btn_loading = false
            }, 1300);
          },
        })
      },
      SelectionChange(data) {
        this.idsarr = data
      },
      // Update Availability of Special Case
      Updateorder() {
        this.btn_loadingd = true
        this.$post({
          url: "/app/saleorder/UpdateAvailability",
          data: {
            api_id: this.api_id
          },
          success: (res) => {
            this.$notify({
              title: 'success',
              type: 'success'
            });
            setTimeout(() => {
              this.btn_loadingd = false
              this.pagechage(1)
            }, 1300);
          },
          tip: () => {
            setTimeout(() => {
              this.btn_loadingd = false
            }, 1300);
          },
        })
      },
      exportNew() {
        if (!this.idsarr.length) {
          this.$message({
            message: 'Please Select Assembly Order',
            type: 'warning'
          });
          return
        }
        this.btn_loadingg = true
        let ids = this.idsarr.map(val => val.id).join()
        let url = `${this.httpPath}/app/digital_orders/exportList?token=${getToken()}&api_id=${this.api_id}&ids=${ids}`
        window.open(url)
        setTimeout(() => {
          this.btn_loadingg = false
        }, 500);
      },
      // Export Picking List
      exportList() {
        if (!this.idsarr.length) {
          this.$message({
            message: 'Please Select Assembly Order',
            type: 'warning'
          });
          return
        }
        let ids = []
        this.idsarr.map((item) => {
          ids.push(item.id)
        })
        let that = this
        this.btn_loadinge = true
        this.$post({
          url: "/app/digital_orders/exportDigitalPickingList",
          data: {
            api_id: this.api_id,
            ids: ids.join()
          },
          success: (res) => {
            this.$alert('Export successful, are you sure to download?', 'success', {
              confirmButtonText: 'Download',
              callback: () => {
                that.idsarr = []
                that.btn_loadinge = false
                that.copy(res.data.file_name)
                window.open(res.data.file_path)
              }
            });
          },
          tip: () => {
            setTimeout(() => {
              this.btn_loadinge = false
            }, 1300);
          },
        })
      },
      copy(data) {
        var input = document.createElement('input')
        input.value = data;
        document.body.appendChild(input)
        input.select()
        document.execCommand('copy')
        document.body.removeChild(input)
      },
      getdata() {
        this.$post({
          url: "/app/api_config/info",
          data: {
            api_id: this.api_id
          },
          success: res => {
            this.formData.title = 'PICKING LIST #';
            this.formData.content = `Dear Warehouse Team,\nPlease pick item(s)as per attached file. Thank you`;
            this.formData.to_email = res.data.to_email;
          },
          tip: () => { },
        })
      },
      getfile() {
        if (!this.idsarr.length) {
          this.$message({
            message: 'Please Select Assembly Order',
            type: 'warning'
          });
          return
        }
        this.formData.attachment = ''
        let ids = []
        this.idsarr.map((item) => {
          ids.push(item.id)
        })
        this.loading = true
        this.$post({
          url: "/app/digital_orders/exportDigitalPickingList",
          data: {
            api_id: this.api_id,
            ids: ids.join()
          },
          success: (res) => {
            this.formData.attachment = res.data.file_name
            this.show = true;
            this.loading = false
          },
          tip: () => {
            setTimeout(() => {
              this.loading = false
            }, 1300);
          },
        })

      },
      dialogClose() {
        this.btn_loadingf = false
        this.formData = {
          title: '',
          content: '',
          to_email: '',
          attachment: '',
        }
        this.show = false
      },
      confirmEmail() {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.btn_loadingf = true
            this.$post({
              url: '/app/saleorder_goods/emailPickingList',
              data: {
                api_id: this.api_id,
                ...this.formData,
              },
              success: (res) => {
                this.$notify({
                  title: res.msg,
                  type: 'success'
                });
                this.btn_loadingf = false
                this.show = false
              },
              tip: () => {
                this.btn_loadingf = false
              },
            })
          }
        })
      },
    },
    created() {
      this.getlist()
    },
  }
</script>
<style lang='scss' scoped>
  .indexbox {
    width: 100%;
    height: 100%;
    .searchbox {
      width: 100%;
      padding: 6px 15px;
      background: #fff;
      border-bottom: 1px solid #babdcd;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .inp {
          margin-right: 10px;
        }
        .topboxs {
          position: relative;
          padding: 10px;
          .tit {
            position: absolute;
            top: 0px;
            left: 20px;
            padding: 2px 4px;
            background-color: #fff;
            z-index: 10;
            line-height: 1;
            font-size: 14px;
          }
        }
        /deep/.el-button {
          margin: 5px 10px;
        }
      }
      .download {
        background: #fff !important;
        color: #19cbbf !important;
      }
      /deep/.el-button {
        background-color: #d6f3e3;
        border-radius: 10px;
        color: #3b3b3b;
        border-color: #d6f3e3;
        align-self: flex-end;
        margin: 5px;
      }
    }
    .tablebox {
      width: 100%;
      padding: 20px;
    }
    /deep/.el-dialog {
      min-width: 400px !important;
      .topbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          font-weight: 550;
        }
      }
      .el-dialog__body {
        padding-top: 10px;
      }
    }
    /deep/.el-dialog {
      min-width: 400px !important;
      .topbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          font-weight: 550;
        }
      }
      .el-dialog__body {
        padding-top: 10px;
      }
    }
    /deep/.el-upload {
      width: 100%;
      .el-upload-dragger {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .btntop {
      width: 100%;
      text-align: right;
      padding: 0 0 15px;
    }
    .btnbox {
      width: 100%;
      text-align: center;
      padding: 20px 0 0;
      /deep/.el-button {
        width: 88%;
        height: 40px;
        font-size: 15px;
      }
    }
  }
</style>
